<template>
  <div>
    <div class="columns is-multiline">
      <div class="column is-12">
        <Card content-class="widget" :title="cs.title">
          <b-table
            :data="cs.list"
            :total="total"
            height="80"
            paginated
            :per-page="perPage"
            :current-page="currentPage"
            hoverable
            mobile-cards
          >
            <template v-for="head in cs.table.columns">
              <b-table-column
                :key="head.key"
                :visible="head.visible"             
                :label="head.label"
                :width="head.width"
                :sortable="head.sortable"
                :searchable="cs.table.filterable && head.filterable"
                v-bind="head"
              >
                <!-- site -->
                <template v-if="head.key == 'site'" v-slot="props">
                  <img
                    height="20"
                    width="20"
                    :src="'/img/sites/' + props.row.site.id + '.svg'"
                  />
                </template>
                <!-- ticket -->
                <template v-else-if="head.key === 'ticket'" v-slot="props">
                  <router-link
                    v-if="props.row.actions.read.ticket"
                    :to="getUrl([props.row.id], cs.links.read.ticket.href)"
                  >
                    {{ props.row.id }}
                  </router-link>
                  <span v-else>{{ props.row.id }}</span>
                </template>
                <!-- User -->
                <template v-else-if="head.key === 'user'" v-slot="props">
                  <router-link
                    v-if="props.row.actions.read.profile"
                    :to="getUrl([props.row.user.id], cs.links.read.profile.href)"
                    >{{
                      props.row.user &&
                      props.row.user.fname + " " + props.row.user.lname
                    }}</router-link
                  >
                  <span v-else>{{
                    props.row.user &&
                    props.row.user.fname + " " + props.row.user.lname
                  }}</span>
                </template>
                <!-- subject -->
                <template v-else-if="head.key === 'tsubject'" v-slot="props">
                  <span>{{ props.row.subject.label }}</span>
                </template>
                <!-- tcreated  -->
                <template v-else-if="head.key === 'tcreated'" v-slot="props">
                  <b-tooltip position="is-bottom" type="is-primary is-light">
                    <span>
                      {{
                        (props.row.created_at && props.row.created_at.ago) ||
                        "-"
                      }}
                    </span>
                    <template v-slot:content>
                      <span>
                        {{
                          (props.row.created_at &&
                            props.row.created_at.formatted) ||
                          "-"
                        }}
                        <span class="has-text-grey">{{
                          props.row.created_at && props.row.created_at.time
                        }}</span>
                      </span>
                    </template>
                  </b-tooltip>
                </template>
              </b-table-column>
            </template>

            <EmptyBlock slot="empty" icon="cart" />
          </b-table>
          <Pagination
            :per-page="perPage"
            no-per-pages
            :total="total"
            :current-page="currentPage"
            @update-per-page="(val) => (perPage = val)"
            @update-current-page="(val) => (currentPage = val)"
          />
        </Card>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    cs: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      perPage: 6,
      currentPage: 1,
      total: 0,
    };
  },

  created() {
    this.total = this.cs.list.length || 0;
  },
  methods: {
  },
};
</script>
