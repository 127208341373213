<template>
  <div>
    <div class="columns is-multiline">
      <div class="column is-12">
        <Card
          content-class="widget"
          :title="awaiting.label"
        >
          <b-table
            :data="awaiting.list"
            :loading="loading"
            height="80"
            paginated
            :per-page="perPage"
            :current-page="currentPage"
            hoverable
          >
            <b-table-column
              v-slot="props"
              field="id"
              :label="this.$i18n.t('id')"
              width="80"
              sortable
            >
              <router-link
                v-if="props.row.links && props.row.links.view[0]"
                :to="props.row.links.view[0].href"
              >
                {{ props.row.links.view[0].label }}
              </router-link>
              <span v-else>{{ props.row.id }}</span>
            </b-table-column>
            <b-table-column
              v-slot="props"
              field="company.name"
              :label="this.$i18n.tc('company', 1)"
              width="150"
              sortable
            >
              <span>
                {{ props.row.company.name ? props.row.company.name : "-" }}
              </span>
            </b-table-column>
            <b-table-column
              v-slot="props"
              field="email"
              :label="this.$i18n.tc('email', 1)"
              width="150"
              sortable
            >
              <span>
                {{ props.row.email ? props.row.email : "-" }}
              </span>
            </b-table-column>
            <b-table-column
              v-slot="props"
              field="created_at"
              :label="this.$i18n.t('date')"
              width="150"
              sortable
            >
              <span>
                {{ props.row.created_at && props.row.created_at.formatted || "-" }}
              </span>
            </b-table-column>
            <EmptyBlock
              slot="empty"
              icon="account-supervisor"
            />
          </b-table>
          <Pagination
            :per-page="perPage"
            no-per-pages
            :total="awaiting.list.length"
            :current-page="currentPage"
            @update-per-page="val => (perPage = val)"
            @update-current-page="val => (currentPage = val)"
          />
        </Card>
      </div>
    </div>
  </div>
</template>
  
<script>
export default {
	props: {
	  awaiting: {
      type: Object,
      required: true
	  },
	  loading: {
      type: Boolean,
      default: false
	  }
	},
	data() {
	  return {
      perPage: 6,
      currentPage: 1,
	  }
	},
	created() {
	},
};
</script>
  