<template>
  <Page has-actions>
    <b-loading v-if="loading" :is-full-page="false" :active="true" />
    <div class="columns is-multiline">
      <Overview
        v-if="overview.length"
        class="column is-12 mb-0"
        :overview="overview"
        :loading="loading"
      />
      <Awaiting
        v-if="widgets && widgets.awaiting"
        class="column is-6 pr-4"
        :awaiting="widgets.awaiting"
        :loading="loading"
      />
      <Cs
        v-if="widgets && widgets.cs"
        class="column is-6 pr-4"
        :cs="widgets.cs"
      />
    </div>
  </Page>
</template>

<script>
import Page from "@/layout/Page";
import Awaiting from "@/components/modules/Dashboard/Awaiting";
import Overview from "@/components/modules/Dashboard/Overview";
import Cs from "@/components/modules/Dashboard/Cs";

export default {
  components: {
    Page,
    Awaiting,
    Overview,
    Cs,
  },
  data() {
    return {
      overview: [],
      widgets: [],
      hasContent: false,
      loading: false,
    };
  },
  created() {
    this.getItems();
  },
  methods: {
    getItems() {
      this.loading = true;
      this.$axios
        .get(`/dashboard`)
        .then((res) => {
          this.widgets = res.data.widgets;
          this.overview = res.data.overview;
          this.updateTitle(this.$t("dashboard"));
        })
        .catch((e) => this.$root.clientError(e))
        .finally(() => (this.loading = false));
    },
  },
};
</script>
