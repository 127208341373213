<template>
  <div>
    <div class="columns is-multiline">
      <!-- <div class="column is-3"> -->
      <!-- <template slot="header">
        <div class="level mb-0 py-2"> -->
      <div
        v-if="loading"
        class="column is-12"
      >
        <div class="columns is-multiline">
          <div
            v-for="(i,index) in 4"
            :key="index"
            class="column is-3 pr-4"
          >
            <b-skeleton           
              position="is-centered"
              width="100%"
              height="130px"
              animated
            />
          </div>
        </div>
      </div>
      <div
        v-for="(opt,index) in overview"
        :key="index"
        class="column is-3 pr-4"
      >
        <Card>
          <div class="level-item has-text-centered m-4">
            <div>
              <p class="heading">
                {{ opt.label }}
              </p>
              <p
                class="title"
              >
                {{ opt.value }}
              </p>
            </div>
          </div>
        </Card>
      </div>
    </div>
  </div>
  <!-- </template>
    </div> -->
  <!-- </div> -->
</template>
  
  <script>
  export default {
	props: {
	  overview: {
      type: Array,
      required: true
	  },
    loading: {
      type: Boolean,
      default: false,
    }
	},
	data() {
	  return {
		sstats: [{label: " Awaiting accounts", value :12},
    {label: " Text 1", value :"150"},
    {label: " Text 2", value :"1 765 233"},
    {label: " Text 3", value :"200,000 €"}],
	  }
	},
	created() {
	},
  };
  </script>
  